<template>
  <div id="questions" class="main__wrapper questions">
    <div class="block_container">
      <h2 class="questions__title title-h2">
        {{ $t('profile.questionsAnswers') }}
      </h2>
      <div class="questions__main-first">
        <router-link
          v-for="item in questions"
          :key="item.link"
          class="questions__item"
          :to="{ path: `/questions/${item.link}` }"
        >
          <h5 class="questions__item_title title-h4">
            {{ $t(`${item.title}`) }}
          </h5>
          <div class="questions__item_text">
            {{ $t(`${item.text}`) }}
          </div>
        </router-link>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      questions: [
        {
          link: 'tour-operator-license-procedure',
          title: 'faq.tour-operator-license-procedure.title',
          text: 'faq.tour-operator-license-procedure.short_description',
        },
        {
          link: 'hotel-categorization-procedure',
          title: 'faq.hotel-categorization-procedure.title',
          text: 'faq.hotel-categorization-procedure.short_description',
        },
        {
          link: 'get-kep',
          title: 'faq.get-kep.title',
          text: 'faq.get-kep.description.section_0',
        },
        {
          link: 'get-kep-fiscal',
          title: 'faq.get-kep-fiscal.title',
          text: 'faq.get-kep-fiscal.question_text',
        },
        {
          link: 'e-signature',
          title: 'faq.e-signature.title',
          text: 'faq.e-signature.question_text',
        },
      ],
    };
  },

  methods: {
    goToHome() {
      this.$router.push({ name: 'questions' });
    },
  },
};
</script>

<style lang="sass" scoped>
.questions
  // background-color: $background_secondary
  background-color: transparent

.questions__title
  margin-bottom: 4rem

.questions__subtitle_wrapper
  display: flex
  gap: 8rem
  @include s
    gap: 3rem
    flex-direction: column

.questions__subtitle-item
  width: 50%
  @include s
    width: 100%

.questions__subtitle
  font-size: 18px
  line-height: 28px
  margin-bottom: 40px
  padding-bottom: 10px
  letter-spacing: 0.04em
  color: $gray
  border-bottom: 2px solid
  @include xs
    margin-bottom: 20px

.questions__main-first
  display: flex
  flex-wrap: wrap
  gap: 2rem 8rem
  margin-bottom: 4rem
  @include m
    gap: 1rem 6rem
  @include s
    gap: 1rem
  @include xs
    margin-bottom: 1rem

.questions__main-second
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  // gap: 8rem
  // @include s
  //   gap: 3rem

// .questions__item-second
//   width: 47%
//   @include xs
//     width: 100%
//   // border: solid 1px red

.questions__item
  display: flex
  flex-direction: column
  width: 385px
  border-top: 2px solid
  padding: 3rem 0
  flex: 1 1 auto
  color: #000 !important
  &:hover
    text-decoration-line: underline
    cursor: pointer
  @include s
    padding-bottom: 1rem

.title-h6
  color: $gray

.questions__item_title
  padding-bottom: 25px
  &:hover
    text-decoration-line: underline
    cursor: pointer

.questions__item_text
  margin-bottom: 30px
  flex: 1 1 auto
  font-size: 18px
  line-height: 28px
  @include s
    font-size: 14px
    line-height: 22px

.questions__item_wrapper a
  display: block
  color: $black
</style>
